/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";
import { MyAppWideContextProvider } from "./src/context/my-app-wide-context";
import "bootstrap/dist/css/bootstrap.css";
import "./src/styles/global.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBuilding,
  faFistRaised,
  faHandHoldingDollar,
  faHandHoldingUsd,
  faHeartBroken,
  faLandmark,
  faVoteYea,
  faBars,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFistRaised,
  faVoteYea,
  faBuilding,
  faHeartBroken,
  faHandHoldingUsd,
  faLandmark,
  faHandHoldingDollar,
  faBars,
  faCaretUp,
  faCaretDown
);

export const wrapRootElement = ({ element }) => (
  <MyAppWideContextProvider>{element}</MyAppWideContextProvider>
);

import React, { createContext, useContext, useState, useReducer } from "react";

const MyAppWideContext = createContext();

const initialState = {
  selectedEthosIndex: 0,
  selectedPracticeAreaIndex: 2,
  aboutVideoLoading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PRACTICE_AREA_INDEX":
      return {
        ...state,
        selectedPracticeAreaIndex: action.payload,
      };
    case "SET_ETHOS_INDEX":
      return {
        ...state,
        selectedEthosIndex: action.payload,
      };
    case "SET_ABOUT_VIDEO_LOADING":
      return {
        ...state,
        aboutVideoLoading: action.payload,
      };
    default:
      return "NOT AN APPROPRIATE FUNCTION";
  }
};

export function MyAppWideContextProvider({ children }) {
  const [currentPath, setCurrentPath] = useState("");
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedPracticeAreaIndex, selectedEthosIndex, aboutVideoLoading } = state;

  return (
    <MyAppWideContext.Provider
      value={{
        currentPath,
        setCurrentPath,
        aboutVideoLoading,
        selectedPracticeAreaIndex,
        dispatch,
      }}
    >
      {children}
    </MyAppWideContext.Provider>
  );
}

export function useMyAppWideContext() {
  return useContext(MyAppWideContext);
}
